
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
@import 'vxe-table/styles/variable.scss';
$vxe-primary-color: $themeColor;
$vxe-table-checkbox-range-border-color: $themeColor;
$vxe-pager-background-color: transparent;

@import 'vxe-table/styles/icon.scss';
@import 'vxe-table/styles/table.scss';
@import 'vxe-table/styles/column.scss';
@import 'vxe-table/styles/header.scss';
@import 'vxe-table/styles/footer.scss';
@import 'vxe-table/styles/pager.scss';
@import 'vxe-table/styles/input.scss';
@import 'vxe-table/styles/select.scss';

.vxe-table.border--inner .vxe-table--header-wrapper,
.vxe-table.border--none .vxe-table--header-wrapper {
  min-height: 50px;
  background-color: #f3f3f3;
  border-bottom: 1px solid #e4e4e4;
}
