
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    





























.icon {
  height: 14px;
  max-width: 20px;
  max-height: 12px;
  margin-right: 4px;
  .icon-img {
    max-width: 20px;
  }
}
