
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    




















































































































































































































































































































.paramsEditorResizer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: ew-resize;

  &:hover {
    background-color: rgba(#000, 0.3);
  }
}

.keyCol {
  position: relative;
}

.paramsEditor {
  width: 100%;
  border: 1px solid #ccc;

  td {
    padding: 2px 4px;
  }

  .opCol {
    width: 10px;
    padding-left: 2px;
    padding-right: 6px;
    white-space: nowrap;
  }

  th {
    font-weight: normal;
    padding-left: 4px;
    color: #ccc;
  }
}

.paramInputBox {
  width: 100%;
  box-sizing: border-box;
  min-width: 0;
  border: 1px solid #ccc;
}

.opIcon {
  cursor: pointer;
}
