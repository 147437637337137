
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    














































































































































































































































































































#xy-nav-menu .ant-menu-item,
#xy-nav-menu .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0;
}

//导航
#xy-runtime-layout .light-header,
#xy-preview-layout .light-header,
#xy-runtime-layout .dark-header,
#xy-preview-layout .dark-header {

  // 导航菜单组（不含下拉框）
  #xy-nav-menu.ant-menu {
    .ant-menu-submenu {
      border-bottom: 0;
    }
    .ant-menu-item,
    .ant-menu-submenu > .ant-menu-submenu-title {
      line-height: 53px;
      border-bottom: 3px solid  transparent;
      background: transparent;
      border-radius: 0;
      &:hover {
        border-bottom: 3px solid transparent;
        color: var(--wuji-primary-color);
      }
    }
    .ant-menu-submenu-selected,
    .ant-menu-submenu-open {
      color: var(--wuji-text-color);
      > .ant-menu-submenu-title {
        color: var(--wuji-primary-color);
      }
    }

    .ant-menu-submenu-selected {
      > .ant-menu-submenu-title,
      > .ant-menu-submenu-title:hover {
        border-bottom: 3px solid  var(--wuji-primary-color);
      }
    }
  }
}

// 菜单下拉框
.xy-nav-menu-popup-light, .xy-nav-menu-popup-dark {
  min-width: 128px;
  max-width: 384px;

  .ellipsis {
    display: inline-block;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
  }
  .ant-menu-item {
    padding: 0 20px 0 12px !important;
  }
  .ant-menu-submenu-title {
    padding:0 48px 0 12px !important;
  }
  .title-container {
    display: flex;
    align-items: center;
  }

  // 导航菜单item背景圆角
  .ant-menu {
    .ant-menu-item,.ant-menu-submenu .ant-menu-submenu-title {
      border-radius: 0;
    }
  }
}

// 菜单下拉框
.xy-nav-menu-popup-light.ant-menu-light.ant-menu-submenu-popup .ant-menu {
  .ant-menu-item:not(.ant-menu-item-selected),
  .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
    color: var(--wuji-text-color);
    &:hover {
      color: var(--wuji-text-color); // 菜单item hover字体颜色
      background: var(--wuji-primary-bg-color);
    }
    &:active {
      color: var(--wuji-text-color);
      background: rgba(0, 0, 0, 0.04); // 菜单item 点击背景色
    }
  }

  .ant-menu-item.ant-menu-item-selected,// 没有子菜单 被选中
  .ant-menu-submenu-selected > .ant-menu-submenu-title {// 有子菜单 选中
    color: var(--wuji-text-color);
    background:rgba(0, 0, 0, 0.04);
    .anticon,.anticon + span {
      color: var(--wuji-text-color);
    }
  }


  // 箭头色
  .ant-menu-submenu .ant-menu-submenu-title,
  .ant-menu-submenu .ant-menu-submenu-title:hover,
  .ant-menu-submenu .ant-menu-submenu-title:active {
    .ant-menu-submenu-arrow {
      opacity: 1;
      &::before,&::after {
        background: #4A5970;
      }
    }
  }
}


/*****
    深色导航
********/
// 菜单下拉框
.xy-nav-menu-popup-dark.ant-menu-dark.ant-menu-submenu-popup {
  .ant-menu.ant-menu-sub {
    background: var(--wuji-primary-bg-color-dark);
  }
  .ant-menu-item:not(.ant-menu-item-selected),
  .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
    color: var(--wuji-text-color-dark);
    &:hover {
      color: var(--wuji-text-color-dark);
      background: rgba(255, 255, 255, 0.08);
    }
    &:active {
      color: var(--wuji-text-color-dark);
      background: rgba(255, 255, 255, 0.04);
    }
  }

  .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 1;
    &::before,&::after {
      background: rgba(255, 255, 255, 0.48);
    }
  }


  .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title, // 子菜单 选中
  .ant-menu-item.ant-menu-item-selected { // 没有子菜单 被选中
    color: var(--wuji-primary-color);
    background: rgba(255, 255, 255, 0.08);
    &:hover,&:active,.anticon,.anticon + span {
      color: var(--wuji-primary-color);
    }
    .ant-menu-submenu-arrow {
      &::before,&::after {
        background: var(--wuji-primary-color);
      }
    }
  }
}

