
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.root {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
}

.argForm{
  .argFormContent {
    padding: 0;
    // border: 1px solid rgba(0, 0, 0,.2);
    // border-radius: 4px;
  }
}

.argFormItem{
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  .label{
    font-size: 14px;
    color: #000;
    line-height: 22px;
    margin-top: 4px;
    margin-bottom: 2px;
    span{
      opacity: .7;
      font-weight: normal;
    }
  }
}

.parentFormItem {
  margin-bottom: 12px;
}
