
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    

















































































































































































.header {
  z-index: 1001;
  height: var(--xy-header-height);
  line-height: var(--xy-header-height);
  padding: 0px 4px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.1);
  background: #fff;
  .ant-menu {
    background: transparent;
  }
}
.divider{
  margin: 12px 0;
}

.vertical-center{
  display: flex;
  align-items: center
}

.icon-button{
  font-size: 20px;
  padding: 16px 12px;
}

#xy-runtime-layout .dark-header,
#xy-preview-layout .dark-header {
  background-color: var(--wuji-primary-bg-color-dark);
  .title {
    color: var(--wuji-text-color-dark)
  }
}
