
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    






























.xy-pagelet-container {
  position: relative;
  min-height: max(100%, 1em);
  .xy-pagelet-container-placeholder {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    border: 1px dashed;
  }
}
