
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    





















.xy-alert {
  width: 100%;
  padding: 5px 0;
  & > * {
    width: 100%;
  }
}
