
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    











































































































.modal-select {
  width: 100%;
  display: block;
  flex: 1;
  ::v-deep .ant-select-selection-selected-value{
    width: 100%;
  }
}

.modal-name {
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-button {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
}
