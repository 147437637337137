
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    































.not-found {
  position: relative;
  height: 100%;
  .content {
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
    img {
      display: inline-block;
      vertical-align: top;
      font-size: 0px;
    }
    .explain {
      display: inline-block;
      padding-top: 60px;
      .title {
        color: #434e59;
        font-size: 72px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 20px;
      }
      .message {
        line-height: 28px;
        margin-bottom: 16px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}
