
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    























































































































































































































































































































































































































































































































#xy-runtime-layout {
  width: 100%;
  position: relative;
  #xy-runtime-header {
    overflow: hidden;
    position: relative;
    z-index: 20;
    white-space: nowrap;
  }

  #xy-runtime-wrap {
    position: relative;
    display: flex;
    #xy-runtime-sidebar {
      flex-shrink: 0;
      position: sticky;
      top: 0;
      z-index: 2;
      &.miniProgram{
        position: fixed;
        top: var(--xy-header-height);
        height: 100%;
        z-index: 999;
      }
    }
    #xy-runtime-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      .xy-runtime-main-pagelet {
        flex: 1;
      }
      #xy-runtime-footer {
        flex-shrink: 0;
      }
      &.header-scroll-hide .page-header{
        // position: fixed;
      }
      .page-header {
        background: #fff;
        border-bottom: 1px solid #e8e8e8;
        // position: sticky;
        z-index: 19;
        width: 100%;
        // top: 0%;
      }
    }
  }

  .mock-user-warning {
    position: absolute;
    z-index: 1;
    width: 240px;
    right: 20px;
  }

  // 页头
  .affix-container,
  .affix-container .ant-affix {
    background-color: #fff;
  }

  /*****
      菜单
  ********/
  .ant-menu {
    .ant-menu-item:not(.ant-menu-item-selected),
    .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
      color: var(--wuji-text-color);
      &:hover {
        color: var(--wuji-text-color); // 菜单item hover字体颜色
        background: var(--wuji-primary-bg-color);
      }
      &:active {
        color: var(--wuji-text-color);
        background:rgba(0, 0, 0, 0.04); // 菜单item 点击背景色
      }
    }

    .ant-menu-item.ant-menu-item-selected { // 没有子菜单 被选中
      color: var(--wuji-primary-color);
      background-color: rgba(0,0,0,.04);
      .anticon,.anticon + span, &:hover {
        color: var(--wuji-primary-color);
      }
    }

    .ant-menu-submenu-selected {
      color: var(--wuji-text-color);
      > .ant-menu-submenu-title { // 有子菜单 选中
        &:hover {
        color: var(--wuji-text-color); // 菜单item hover字体颜色
        background: var(--wuji-primary-bg-color);
        }
        .anticon {
          color: var(--wuji-primary-color);
        }
      .anticon + span {
          color: var(--wuji-text-color);
        }
      }
    }

    // 菜单item背景圆角
    .ant-menu-item, .ant-menu-submenu-title {
      border-radius: 4px;
    }

    // 箭头色
    .ant-menu-submenu .ant-menu-submenu-title,
    .ant-menu-submenu .ant-menu-submenu-title:hover,
    .ant-menu-submenu .ant-menu-submenu-title:active {
      .ant-menu-submenu-arrow {
        opacity: 1;
        &::before,&::after {
          background: #4A5970;
        }
      }
    }
  }

  /*****
      深色菜单
  ********/
  .ant-menu-dark.ant-menu {
    background-color: var(--wuji-primary-bg-color-dark);
    .ant-menu-sub {
      background-color: var(--wuji-primary-bg-color-dark);
    }

    // 菜单阴影
    .ant-menu-inline.ant-menu-sub {
      box-shadow: none;
    }

    .ant-menu-item:not(.ant-menu-item-selected),
    .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
      color: var(--wuji-text-color-dark);
      .anticon {
        color:rgba(255, 255, 255, 0.48);
      }
      &:hover {
        color: var(--wuji-text-color-dark); // 菜单item hover字体颜色
        background: rgba(255, 255, 255, 0.08);
      }
      &:active {
        color: var(--wuji-text-color-dark);
        background:rgba(255, 255, 255, 0.04); // 菜单item 点击背景色
      }
    }

    // 箭头色
    .ant-menu-submenu .ant-menu-submenu-title,
    .ant-menu-submenu .ant-menu-submenu-title:hover,
    .ant-menu-submenu .ant-menu-submenu-title:active {
      .ant-menu-submenu-arrow {
        // opacity: 1;
        &::before,&::after {
          background: rgba(255, 255, 255, 0.48);
        }
      }
    }

    .ant-menu-item.ant-menu-item-selected { // 没有子菜单 被选中
      background-color: rgba(255, 255, 255, 0.08);
    }

    // 子菜单 选中
    .ant-menu-submenu-selected  {
      color: var(--wuji-text-color-dark);
      > .ant-menu-submenu-title {
        &:hover {
          color: var(--wuji-text-color-dark);
          background-color:rgba(255, 255, 255, 0.08);
        }
        .anticon + span {
          color: var(--wuji-text-color-dark);
        }
      }
    }
  }
}
