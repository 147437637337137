
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
// 普通
.modal {
  :global(.ant-modal) {
    padding-bottom: 0px;
  }
  :global(.ant-modal-body) {
    height: calc(90vh - 120px);
  }
}

.editor_wrap {
  height: 100%;
  display: flex;
}

.editor_wrap_left {
  flex-basis: 300px;
  border: 1px solid #d0d7de;
  border-right: none;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 8px;
  overflow: auto;
}

.editor_wrap_right {
  flex: 1;
}

.conflict_title {
  padding: 4px 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.conflict_title_icon {
  margin-right: 4px;
}

.conflict_item {
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 2px;
}

.conflict_item:hover {
  cursor: pointer;
  background-color: #eee;
}

.conflict_item.selected {
  background-color: #eee;
}