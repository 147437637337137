
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.wrapper {
  min-height: calc(100vh - 56px);
  background: #f0f2f5;

  :global(.ant-table) {
    background: #fff;
  }
}
