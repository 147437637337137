
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.operationAndSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.search {
  display: flex;
  align-items: center;
}

.selectWithLabel {
  display: table;
  margin-right: 8px;
  .label {
    display: table-cell;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-right: 0;
    border-radius: 4px 0 0 4px;
    padding: 0 16px;
    text-align: center;
    white-space: nowrap;
  }
  .select {
    width: 240px;
  }
}

.syncResultHeader {
  :global(.ant-collapse) {
    background-color: white;
    border: 0px;

    :global(.ant-collapse-item) {
      border: 0px;
    }
  }

}

.syncResultWrapper {
  :global(.ant-alert) {
    margin-bottom: 4px;
  }
  :global(.ant-alert-warning) {
    border: 0px;
  }
  :global(.ant-alert-success) {
    border: 0px;
  }
}

.generalWrapper {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .searchTag {
    display: flex;
    align-items: center;
  }
}

.activeRow {
  background-color: var(--wuji-primary-bg-color);
}

.disabledRoleUserNameTag {
  opacity: 0.5;
  text-decoration: line-through;
  border-color: transparent;
}

.clickableRoleUserNameTag {
  cursor: pointer;
  opacity: 1;
}

.inputedRoleUserNameTag {
  outline: 2px solid var(--wuji-primary-color);
  outline-offset: -2px;
  opacity: 1;
}
