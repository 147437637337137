
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    




























































































































































































































































































































.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #EEEEEE;
  border-left: 1px solid #F0F2F5;
  padding-right: 24px;

  .title {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 20px;
      color: rgba(0,0,0,0.90);
      line-height: 28px;
      margin: 0;
      padding-left: 12px;
    }
  }
}

