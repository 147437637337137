
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


















































































































































.wrapper {
  background: #dddddd;
}

.menus {
  background: #dddddd;
  padding-top: 16px;
  &-tab {
    padding-left: 24px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-active {
    color: var(--wuji-primary-color) !important;
  }
}

.menus-hidden {
  padding-top: 11px;
}

.ant-menu {
  box-shadow: none !important;
}

.widget-wrapper {
  background: #ffffff;
  padding-left: 24px;
  padding-top: 16px;
}

.menus-wrapper {
  background: #ffffff;
  padding-top: 16px;
}
