
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    










































































































































.dt-entry {
  position: fixed;

  padding: 0px 0px 0px 10px !important;

  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
  right: 0;
  backdrop-filter: blur(5px);

  z-index: 1000;
  overflow:hidden;
  cursor: pointer;
  transition: none !important;

  .title {
    max-width: 0px;
    transition: max-width 0.5s;
  }

  &.hovered{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: auto;
    .title {
      max-width: 200px;
    }
  }
  &-list{
    position: absolute;
    z-index: 1000;
    background-color: white;
    right: 120px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    padding: 5px 0;
    visibility: hidden;
    transition-delay: 2s;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    opacity: 0;

    &.hovered {
      visibility: visible;
      opacity: 1;
    }

    &-item {
    cursor: pointer;
    line-height: 24px;
    padding: 3px 15px;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

