
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    





















































































































































































































































































































































.xy-cgi-form-item{
  margin-bottom: 0px;
}
.xy-cgi-params-form-item{
  .ant-form-item-label{
    line-height: inherit;
    margin-top: 8px;
  }
}
.xy-cgi-params-radio-item{
  .ant-form-item-control-wrapper{
    margin-top: -10px;
  }
}
