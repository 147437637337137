
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  display: block;
  width: 100%;
  .headerRow {
    line-height: 28px;
    text-align: center;
  }
  .row {
    @include center();
    margin: 0 0 2px 0;
    .col {
      flex: 1;
      margin-right: 4px;
    }
    .opCol {
      width: 66px;
      @include center();
    }
  }
}

.addField {
  width: 100%;
}

.draggableHost {
  border: 1px solid #7f0020;
  opacity: 0.6;
}
