
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
























































:root {
  --project-config-sidebar-width: 162px;
}

.page-layout-actions {
  height: 48px;
  background: #ffffff;
  box-shadow: 4px -4px 4px 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  left: var(--project-config-sidebar-width);
  bottom: 0;
  padding: 16px;
  z-index: 2;
  button {
    margin: 0 8px;
  }
}
.page-layout-actions-modal {
  left: 0 !important;
}
.page-layout-actions-fixed {
  position: fixed;
  left: 0;
}
