
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


















































































































































































































.nav-user-popover-light .ant-popover-inner-content,
.nav-user-popover-dark .ant-popover-inner-content {
  padding: 0px;
  min-width: 80px;
}
.nav-user-popover-light.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow,
.nav-user-popover-dark.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: -4px;
}
