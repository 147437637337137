
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    










































































.wrapper {
  position: relative;
  height: 100%;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
