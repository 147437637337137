//无极中台对antdv的样式覆盖都写在这里
/**
中台标准按钮按照设计规范2px
 */
// 按钮组件
@button-border-radius: 2px;

.project-page-modal {
  display: flex;
  overflow: hidden;
  .ant-modal {
    max-width: calc(100vw - 120px);
  }
  .ant-modal-body {
    padding: 24px 8px;
    & > div {
      max-height: 60vh;
      overflow: auto;
      padding: 0 32px 32px;
    }
  }
  .ant-modal-header {
    background: #383838;
    font-size: 16px;
  }
  .ant-modal-title {
    color: #ffffff;
  }
}

.project-config-modal {
  display: flex;
  overflow: hidden;
  .ant-modal {
    max-width: calc(100vw - 120px);
    padding-bottom: 0px;
  }
  .ant-modal-body {
    background-color: #F5F5F5;
    max-height: calc(100vh - 150px);
    overflow: auto;
  }
  .ant-modal-header {
    background: #383838;
    font-size: 16px;
  }
  .ant-modal-title {
    color: #ffffff;
  }
}

// 全局统一下拉框hover颜色
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.04);
}

// 表单样式
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.9)
}

// 表格样式
.ant-table {
  color: rgba(0, 0, 0, 0.9);
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}

// 深色弹窗
@dark-bg-color: #383838;
@dark-font-color: #ffffff;
.dark-modal {
  ::v-deep .ant-modal {
    .ant-modal-close {
      color: @dark-font-color;
      transform: scale(0.8, 0.8);
    }
    .ant-modal-header {
      background-color: @dark-bg-color;
      .ant-modal-title {
        color: @dark-font-color;
      }
    }
    .ant-modal-body {
      padding: 32px;
      // 表单样式
      .ant-form-item {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
        .ant-input,
        .ant-select-selection {
          border-radius: 2px;
        }
      }
    }
    .ant-modal-footer {
      .ant-btn {
        border-radius: 2px;
      }
    }
  }
}

.ant-btn {
  border-radius: @button-border-radius;
}

.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  background: var(--wuji-primary-color);
  &::after {
    height: 2px;
    background: white;
  }
}

// 下拉框的hover的背景色
.ant-dropdown-menu{
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title{
    a {
      color: rgba(0,0,0,0.85);
    }
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
