
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
























































































































































































































































































































.xy-page-container {
  position: relative;
  .xy-page-container-tips {
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    border: 1px dotted rgba(0, 0, 0, 0.6);
  }
}
