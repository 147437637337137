
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    













































































































.tag-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
}
.tag-contain {
  flex: 1;
  white-space: nowrap;
  overflow: scroll;
  position: relative;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.tag-link {
  cursor: pointer;
  padding: 5px 10px;
  background: white;
  border-color: #e5e5e5;
  &-active {
    color: var(--wuji-primary-color);;
  }
}
