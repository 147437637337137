
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    















































































































































































































.xy-modal-container,
.xy-drawer-container {
  /* 弹窗相关变量 */
  --wuji-modal-vertical-gap: 24px;
  --wuji-modal-header-padding: 16px 24px;
  --wuji-modal-header-border-bottom: 1px solid #e8e8e8;
  --wuji-modal-body-padding: 24px;
  --wuji-modal-footer-padding: 10px 16px;
  --wuji-modal-footer-border-top: 1px solid #e8e8e8;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  .xy-modal-container-title {
    padding: var(--wuji-modal-header-padding);
    border-bottom: var(--wuji-modal-header-border-bottom);
    flex-shrink: 0;
    .xy-modal-container-title-text {
      margin: 0;
      color: #161616;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      word-wrap: break-word;
    }
  }

  .xy-modal-container-content {
    padding: var(--wuji-modal-body-padding);
    flex: 1;
    overflow: auto;
  }

  .xy-modal-container-footer {
    padding: var(--wuji-modal-footer-padding);
    border-top: var(--wuji-modal-footer-border-top);
    flex-shrink: 0;
    .xy-modal-container-footer-default {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ant-btn:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.xy-modal-container {
  max-height: calc(100vh - var(--wuji-modal-vertical-gap));
}

.xy-drawer-container {
  height: 100%;
}
