
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    











































































































.container{
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.textStyle {
  font-size: 12px;
}

.header{
  cursor: pointer;
  font-weight: bold;

  .visibleIcon{
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }

  &:hover{
    .visibleIcon{
      opacity: 1;
      transition: opacity 0.1s ease-out;
    }
  }
}

.noDataSchemaText{
  color: black;
  font-size: 14px;

  .methodName{
    color: #979797;
    font-size: 85%;
    border-radius: 3px;
    padding: .2em .4em;
    background-color: rgba(27,31,35,.05);
  }
}

