
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


































































































.formPlaceholder {
  padding: 10px;
  border-radius: 2px;
  border: 1px dashed #ccc;
  text-align: center;
}

.notRecommendedCustom {
  color: #999;
  cursor: pointer;
  text-decoration: underline;

  &:hover, &:focus {
    color: #666;
  }
}
