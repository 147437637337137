
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    










































































.nav-link-item {
  font-size: 14px;
  &:hover {
    color: var(--wuji-primary-color);
    cursor: pointer;
  }
  &#{&}-active {
    color: var(--wuji-primary-color);
  }
}
#xy-runtime-layout .dark-header,
#xy-preview-layout .dark-header {
  .nav-link-item {
    color: var(--wuji-text-color-dark);
    &:hover {
      color: var(--wuji-primary-color);
      background: transparent;
    }
  }
}
