
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    



















































































.option-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

::v-deep .ant-select-selection-selected-value {
  width: 100%;
}
