
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    























































































.navigation {
  width: 260px;
  height: 100%;
  background-color: #fff;
  padding: 10px;

  ::v-deep  .ant-menu-vertical {
    border-right: none;
  }
}
