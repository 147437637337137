
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.mockSwitch-container {
  position: fixed;
  bottom: 0;
}
.codeEditor {
  height: 60vh;
}
.title-input {
  width: 50%;
  margin-bottom: 10px;
}
