
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


































































































































































































































































































































































.nav-switch-popover-light .ant-popover-inner-content ,
.nav-switch-popover-dark .ant-popover-inner-content {
  padding: 0px;
  min-width: 80px;
  max-height: 410px;
  overflow: auto;
}

.nav-switch-popover-dark .ant-popover-inner-content {
  background: var(--wuji-primary-bg-color-dark);
  .nav-switch_options {
    color: var(--wuji-text-color-dark);
    &:hover,&-active {
      color: var(--wuji-text-color-dark);
      background: rgba(255, 255, 255, 0.08);
    }
    &:active {
      background: rgba(255, 255, 255, 0.04);
    }
  }
}

.nav-switch-popover-dark.ant-popover-placement-bottom {
  > .ant-popover-content > .ant-popover-arrow {
    border-top-color: var(--wuji-primary-bg-color-dark);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--wuji-primary-bg-color-dark);
  }
}

.nav-switch-popover-dark.ant-popover-placement-top {
  > .ant-popover-content > .ant-popover-arrow {
    border-right-color: var(--wuji-primary-bg-color-dark);
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: var(--wuji-primary-bg-color-dark);
  }
}
