
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    



























































































































































































































































































































































































































































































































































































.header {
  z-index: 2;
  padding-left: 16px;
  padding-right: 20px;
  height: var(--xy-header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.1);
  background: #fff;
  .ant-menu {
    background: transparent;
  }
}

.nav-submenu-wrapper {
  position: relative;
}

.nav-submenu-content {
  position: absolute;
  right: 100%;
  top: 0;
  background: #fff;
  width: 180px;
  margin-right: 4px;
  border-radius: 0;
  transition: all 0.1s;
  overflow: hidden;
  max-height: 10px;
  opacity: 0;

  .nav-submenu-wrapper:hover & {
    overflow: hidden;
    max-height: 240px;
    opacity: 1;
  }
}

#xy-runtime-layout .dark-header,
#xy-preview-layout .dark-header {
  color: var(--wuji-text-color-dark);
  background-color: var(--wuji-primary-bg-color-dark);
  .title {
    color: #fff;
  }
}

.nav-left{
  display: flex;
  align-items: center;
}

.nav-logo {
  padding-left: 8px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  color: var(--wuji-primary-color);
  .img {
    margin-right: 8px;
    max-height: 32px;
    transform: translateZ(0);
  }
  .title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.flex-container {
  display: flex;
  align-items: center;
}

.nav-user-quick {
  display: flex;
  align-items: center;
  height: 32px;

  .nav-logout {
    font-size: 12px;
    color: #161616;
  }
}
.nav-link-item {
  padding: 10px 12px;
  margin: 0;
  cursor: pointer;
  &:hover {
    background: var(--wuji-primary-bg-color);
  }
  &-logout {
    border-top: 1px solid var(--wuji-primary-bg-color);
    padding-left: 12px;
    margin-top: 4px;
  }
}
