
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    




























































































#xy-client-layout-nav-link.nav-link {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  gap: 32px;
}

