
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


























































































































































































































































































.card-popover .ant-popover-inner-content{
  width: 480px;
  height: 600px;
  overflow: scroll;
  padding: 24px;
  .cherry-previewer{
    padding: 0;
  }
}
.card-popover .ant-popover-title{
  padding: 10px 16px;
}
