
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.root {
  width: 100%;
}
.form {
  width: 100%;
}
.item {
  margin-bottom: 0px;
}
.selectMethod {
  :global {
    .ant-form-item-children {
      display: flex;
      flex-wrap: wrap;
      gap:4px;
      >div{
        max-width: 100%;
      }
    }
  }
}
.flex {
  display: block;
  flex: 1;
}

.types {
  margin: 10px 0;
}
