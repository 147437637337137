
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    




























































































.nav-drop-popover-light .ant-popover-inner-content,
.nav-drop-popover-dark .ant-popover-inner-content {
  padding: 0px;
  min-width: 80px;
  max-height: 410px;
  overflow: auto;

  .nav-link-item {
    padding: 10px 12px;
  }
}

.nav-drop-popover-light .ant-popover-inner-content {
  .nav-drop_options,.nav-link-item {
    &:hover,&-active {
      color: var(--wuji-text-color);
      background:var(--wuji-primary-bg-color);
    }
    &:active {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.nav-drop-popover-dark .ant-popover-inner-content {
  color: var(--wuji-text-color-dark);
  background: var(--wuji-primary-bg-color-dark);
  .nav-drop_options,.nav-link-item {
    &:hover,&-active {
      color: var(--wuji-text-color-dark);
      background:rgba(255, 255, 255, 0.08);
    }
    &:active {
      background:rgba(255, 255, 255, 0.04);
    }
  }
}

.nav-user-popover-dark.ant-popover-placement-bottomRight,
.nav-drop-popover-dark.ant-popover-placement-bottom {
   > .ant-popover-content > .ant-popover-arrow {
    border-top-color: var(--wuji-primary-bg-color-dark);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--wuji-primary-bg-color-dark);
  }
}
