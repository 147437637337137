
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    























































































.xy-wxwork-selector {
  width: 100%;
  .xy-wxwork-selector-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    .xy-wxwork-selector-type {
      flex-basis: 158px;
      margin-right: 8px;
    }
    .xy-wxwork-selector-value {
      flex: 1;
    }
  }
  .xy-wxwork-selector-tips {
    margin-top: 8px;
  }
}
