
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    




























.breadcrumb-contain {
  padding: 0 20px;
  margin: 15px 0;
}
