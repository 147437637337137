
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.reLoginModal {
  :global(.ant-modal-body) {
    padding: 32px 32px 24px;
  }
}

.contentWrap {
  display: flex;
}

.contentIcon {
  font-size: 22px;
  color: #faad14;
  margin-right: 16px;
}

.contentTitle {
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.content {
  margin-top: 8px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}