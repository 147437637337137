
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.compSource{
  text-align: center;
  width:88px;
  min-height:80px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.7);
  border: 2.5px solid transparent;
  margin: 4px;
  flex-shrink: 0;
  &:hover{
    color: white;
    cursor: grab;
    border: 2.5px solid #7f0020;
  }
}
.compSourceList{
  display: flex;
  flex-wrap: wrap;
  margin: 16px;
  justify-content: space-around;
}
.materialPanelBox{
  position: relative;
  overflow: auto;
}
