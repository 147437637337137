
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    































































































































































































.navHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 2px #f0f0f0;
  margin: 0 -12px;
  margin-bottom: 12px;
  font-size: 18px;
  padding: 6px;
  padding-bottom: 10px;
  gap: 4px;
}

.navigation {
  width: 260px;
  height: calc(100vh - var(--xy-header-height));
  background-color: #fff;
  padding: 10px;

  ::v-deep .ant-menu-vertical, .ant-menu-inline{
    border-right: none;
  }
}

.header-container {
  height: var(--xy-header-height);
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #EEEEEE;
  border-left: 1px solid #F0F2F5;

  .title {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 20px;
      color: rgba(0,0,0,0.90);
      line-height: 28px;
      margin: 0;
      padding-left: 12px;
    }
  }
}

.content-wrapper {
  width: 100%;
  min-height: calc(100vh - var(--xy-header-height) * 2);
  padding: 24px;
  background-color: #f0f2f5;

  .container {
    height: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
  }
}

