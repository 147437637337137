
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
























































































































.xy-pagelet-renderer {
  position: relative;
  .loading-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    z-index: 300;

    .loading-content {
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .xy-pagelet-renderer-tips {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
  }
}
.xy-pagelet-renderer-runtime {
  min-height: 100px;
}
