
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
.formItem {
  margin-bottom: 6px;

  :global(.ant-form-item-label) {
    line-height: 22px;
  }
}

.colConfig {
  :global(.ant-form-item-label) {
    line-height: 22px;
  }
}

.formatControl {
  :global(.ant-form-item-control) {
    line-height: 22px;
  }
}

.link {
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;

  .icon {
    margin-right: 4px;
  }
}
