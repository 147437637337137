
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    

























































































.qr-code {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  z-index: 100;
  .icon {
    width: 375px;
    height: 375px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loading-tips {
    padding: 12px 0;
  }
}
