
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    


















































































































































































































































































































































































































































































































































































































































































$menuItemHeight: 40px;
.xy-sidebar {
  &.runtime {
    border-right: 1px solid #eee;
  }

  &.miniProgram {
    border-right: 0;
  }

  // from ui-core/src/comps/Aside.vue
  .xy-sidebar-resizer{
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%;
    margin: 0 -2px;
    cursor: ew-resize;
    z-index: 1;
    right:0;
    &:hover {
      width: 6px;
      margin: 0 -3px;
      background: rgba(#fff, 0.06);
    }
  }

  .drawer-mask{
    position: fixed;
    top: var(--xy-header-height);
    width: 0;
    height: 0;
    transition: background 0.3s ,left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0s 0.3s , height 0s 0.3s;
    z-index: 1;
    &-opened{
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      transition: background 0.3s ,left 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) 0s, width 0s 0s , height 0s 0s;
    }
  }
}

.xy-default-sidebar {
  padding-top: 16px;
  &.collapsed{
    margin-right: 0;
  }

  .ant-layout-sider-children {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-menu-inline{
    width: 100%;
  }

  // 隐藏antd默认的border
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0;
  }

  #xy-sidebar-title {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 16px ;
    margin: 0 8px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    transition: padding 0.3s;
    &.collapsed{
      padding: 0;
    }

    .xy-sidebar-logo {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      overflow-x: hidden;
    }
    .xy-sidebar-text {
      font-size: 20px;
      color:rgba(0,0,0,0.85);
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  #xy-sidebar-menu-title {
    margin: 16px 8px 8px;
    height: $menuItemHeight;
    display: flex;
    align-items: center;
    padding: 0 16px ;
    box-sizing: border-box;
    transition: padding 0.3s;
    &.collapsed{
      padding: 0;
    }

    .xy-sidebar-logo {
      width:24px;
      height:24px;
      background-color: var(--wuji-primary-color);
      border-radius: 100%;
      margin-right: 8px;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      justify-content: center;
      .logo-icon{
        font-size: 14.4px;
        color: #fff;
      }
    }
    .xy-sidebar-text {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  #xy-sidebar-menu {

    &.ant-menu-root {
      flex: 1;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &.hidden {
      display: none;
    }

    .first-menu-item.ant-menu-item,
    .first-menu-item.ant-menu-submenu > .ant-menu-submenu-title {
      padding-left: 16px !important;
    }

    .ant-menu-item, .ant-menu-submenu {
      padding-bottom: 0;
      .icon-placeholder {
        margin-left: -10px;
      }
      i.anticon {
        flex-shrink: 0;
      }
      .ellipsis {
        display: inline-block;
        text-overflow: ellipsis; // 在多行文本的情况下，用...隐藏超出范围的文本
        word-break: break-all;
        overflow: hidden;
      }
    }

    .ant-menu-submenu-open {
      .ant-menu {
        margin-bottom: 8px;
      }
      .ant-menu-submenu-title,.ant-menu-item {
        margin-bottom: 4px;
      }
    }

    .ant-menu-item, .ant-menu-submenu-title {
      height: $menuItemHeight;
      line-height: $menuItemHeight;
      box-sizing: border-box;
      margin: 0 0 8px 0;
      padding-bottom: 0;
    }

    .ant-menu-submenu-title {
      padding-right: 48px;
    }

    // 菜单收起的情况
    .ant-menu-inline-collapsed {
      .ant-menu-item, .ant-menu-submenu-title {
        display: flex;
        justify-content: center;
        .anticon {
          margin-right: 0px;
          line-height: 16px;
        }
      }
      .ant-menu-submenu-selected {
        .anticon {
          color: var(--wuji-primary-color);
        }
      }
    }

    .ant-menu-item, .ant-menu-submenu-title {
      margin-left: 8px;
      margin-right: 8px;
      width: calc(100% - 16px);
      .anticon {
        // 图片与文本的间距为16px
        margin-right: 8px;
        font-size: 16px;
      }
      & > span {
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
    }

    // 去除选中态右侧的边框
    .ant-menu-item::after {
      opacity: 0;
    }
  }
}

.ant-layout-sider-dark .ant-menu-item-selected,
.ant-layout-sider-dark #xy-sidebar-title .xy-sidebar-text,
.ant-layout-sider-dark #xy-sidebar-menu-title .xy-sidebar-text {
  color: #fff;
}

.xy-default-sidebar.ant-layout-sider-dark {
  background: var(--wuji-primary-bg-color-dark);
  .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: var(--wuji-primary-bg-color-dark);
  }
  #xy-sidebar-title {
    border-color: var(--wuji-text-color);
  }
}


.header-hidden {
  display: none;
}

