
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    













































































.xy-modal-size-editor {
  width: 100%;
  &-input {
    // input[type="number"] 隐藏步进器
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
